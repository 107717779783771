<template>
  <div class="container">
    <GroupedList
      v-if="people"
      v-slot="{ item }"
      :items="people"
      group-field="familyName"
      sort-field="familyName"
      class="mt-28"
    >
      <Accordion
        :title="item.givenName + ' ' + item.familyName"
        class="mb-16"
        :hash="'person-' + item.id"
      >
        <PersonCard v-bind="item">
          <MediaImage
            v-if="item.image"
            v-bind="item.image"
            :image-style="imageStyle"
            class="aspect-[4/3] mb-24"
            loading="lazy"
          />
        </PersonCard>
      </Accordion>
    </GroupedList>
  </div>
</template>

<script lang="ts" setup>
import { notNullish } from '@vueuse/core'

defineBlokkliFragment({
  name: 'person_list',
  label: 'Person List',
  editor: {
    noPreview: true,
  },
})

const { data: people } = await useAsyncGraphqlQuery('personList', null, {
  transform: function (data) {
    return (data.data.entityQuery.items || [])
      .map((v) => {
        if (v && 'email' in v && v.id) {
          return v
        }
        return null
      })
      .filter(notNullish)
  },
  graphqlCaching: {
    client: true,
  },
})

const imageStyle = defineImageStyle({
  type: 'sizes',
  aspectRatio: 4 / 3,
  sizes: {
    xs: 400,
    sm: 600,
    md: 836,
  },
})
</script>
