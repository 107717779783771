<template>
  <div class="container my-32">
    <ClientOnly>
      <div
        :id="id"
        ref="wrapper"
        class="rounded-large shadow overflow-hidden bg-white"
      >
        <div v-if="isEditing" class="p-32 ck-content">
          <h3>mailXpert</h3>
          <div v-if="!isValid" class="text-red-500">
            Eingegebene URL ist ungültig. Die URL muss den String /de/
            enthalten.
          </div>
          <p>
            <strong>{{ options.mailxpertSource }}</strong>
          </p>
          <p>
            Das Fomular wird während dem Editieren nicht angezeigt.<br />
            Mit «Responsive Vorschau» kann eine Vorschau des Formulars angezeigt
            werden.
          </p>
        </div>
      </div>
    </ClientOnly>
  </div>
</template>

<script lang="ts" setup>
const { options, uuid, isEditing } = defineBlokkliFragment({
  name: 'mailxpert',
  label: 'mailXpert',
  description: 'Zeigt das Formular für die mailXpert Newsletter-Anmeldung an.',
  options: {
    mailxpertSource: {
      type: 'text',
      label: 'src',
      default:
        'https://nl.mailxpert.ch/e/e2737e1b96ab0a58/de/form/bdd5e8df-da11-4551-8718-34cf15981092.html?render=container',
    },
  },
  editor: {
    noPreview: true,
  },
})

const language = useCurrentLanguage()

const id = computed(() => 'mailxpert-' + uuid)

const wrapper = ref<HTMLDivElement | null>(null)

const isValid = computed(() =>
  (options.value.mailxpertSource || '').includes('/de/'),
)

const src = computed(() => {
  const source = options.value.mailxpertSource
  if (typeof source !== 'string') {
    return
  }

  return source.replace('/de/', `/${language.value}/`)
})

const { $script } = useScript({
  src: 'https://api.mailxpert.ch/forms.js',
  crossorigin: false,
})

onMounted(() => {
  if (isEditing || !src.value) {
    return
  }
  $script
    .load()
    .then(() => {
      try {
        // @ts-ignore
        const mailxpert = window.mailxpert

        if (!mailxpert || !wrapper.value || !options.value.mailxpertSource) {
          return
        }

        mailxpert.forms.include({
          src: src.value,
          element: wrapper.value,
          type: 'subscription_form',
        })
      } catch (_e) {
        // Noop.
      }
    })
    .catch()
})
</script>
