<template>
  <div class="container my-32">
    <div class="overflow-hidden rounded-large shadow-lg">
      <iframe :src="src" class="h-[1400px] w-full border-none lg:h-[1120px]" />
    </div>
  </div>
</template>

<script lang="ts" setup>
defineBlokkliFragment({
  name: 'voting',
  label: 'Voting',
})

const language = useCurrentLanguage()

const src = computed(() => {
  return 'https://publica.minerva.info/?lang=' + language.value
})
</script>
