<template>
  <div @click="onClick">
    <slot />
  </div>
</template>

<script setup lang="ts">
import { isExternalUrl } from '~/helpers/url'
import { toRelativeUrl } from '#vuepal/helpers/url'

const router = useRouter()

function onClick(e: MouseEvent) {
  if (!(e.target instanceof HTMLElement)) {
    return
  }
  const link = e.target.closest('a')
  if (!(link instanceof HTMLAnchorElement)) {
    return
  }

  const href = link.href
  if (isExternalUrl(href)) {
    return
  }

  // Webform.
  if (href.includes('/form/')) {
    return
  }

  // Handle media canonical URLs.
  const match = router.resolve(href)
  if (match.name && match.name.toString().startsWith('media-canonical')) {
    return
  }

  // Internal link that can be Nuxt routed.
  e.preventDefault()
  router.push(toRelativeUrl(href))
}
</script>
