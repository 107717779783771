<template>
  <a
    v-blokkli-droppable:field_media_image
    :href="imageFull?.mediaFileUrl?.path"
    :data-pswp-width="sourceWidth"
    :data-pswp-height="sourceHeight"
    data-cropped="true"
    target="_blank"
    class="relative block overflow-hidden rounded-large"
    :class="{
      'row-span-2': isPortrait,
      'row-span-1': !isPortrait,
    }"
  >
    <MediaImage
      v-bind="image"
      :image-style="imageStyle"
      img-class="absolute left-0 top-0 h-full w-full object-cover"
    />
  </a>
</template>

<script lang="ts" setup>
import type { ParagraphImageFragment } from '#graphql-operations'

const props = defineProps<{
  image?: ParagraphImageFragment['image']
  imageFull?: ParagraphImageFragment['imageFull']
}>()

defineBlokkli({
  bundle: 'image',
  renderFor: [
    {
      parentBundle: 'gallery',
    },
  ],
})

const sourceWidth = computed(
  () => props.image?.image?.file?.rokka?.sourceWidth || 200,
)

const sourceHeight = computed(
  () => props.image?.image?.file?.rokka?.sourceHeight || 200,
)

const imageStyle = defineImageStyle({
  type: 'sizes',
  sizes: {
    xs: 400,
  },
})

const ratio = computed(() => sourceWidth.value / sourceHeight.value)

const isPortrait = computed(() => ratio.value < 1.3)

defineOptions({
  name: 'ParagraphImageInsideGallery',
})
</script>
