<template>
  <div
    :id="id"
    ref="el"
    class="focus-within:focusable group rounded-huge bg-white shadow-lowered transition scroll-mt-32"
    :class="{ '!shadow-elevated': isOpened, 'hover:shadow': !isOpened }"
    :open="isOpened"
  >
    <a
      :href="anchor"
      :aria-expanded="isOpened"
      :aria-controls="id + '-content'"
      class="flex justify-between p-16 text-blue-700 focus:outline-0 lg:p-30 items-center"
      :class="{ 'group-hover:text-blue-500': !isOpened }"
      @click.prevent="onClick"
    >
      <span
        class="font-condensed md:text-lg font-bold !leading-tight lg:text-2xl"
        v-html="title"
      />
      <div class="shrink-0 ml-auto size-24 md:size-32">
        <div class="rounded-full bg-current p-4 size-full">
          <SpriteSymbol
            name="nav-arrow-down"
            class="size-full text-white transition"
            :class="{ '-scale-y-100': isOpened }"
          />
        </div>
      </div>
    </a>
    <VuepalTransitionHeight opacity :duration="300">
      <div
        v-show="isOpened"
        :id="id + '-content'"
        :aria-labelledby="id"
        role="region"
        :aria-hidden="!isOpened"
      >
        <div class="p-16 !pt-0 lg:p-30">
          <slot />
        </div>
      </div>
    </VuepalTransitionHeight>
  </div>
</template>

<script lang="ts" setup>
import { slugify } from '#vuepal/helpers/url'

const props = defineProps<{
  title: string
  hash?: string
  alwaysOpen?: boolean
}>()

const route = useRoute()

const el = ref<HTMLDivElement | null>(null)

const id = computed(() => {
  return props.hash || slugify(props.title)
})

const anchor = computed(() => {
  return '#' + id.value
})

const open = ref(false)

const isOpened = computed(() => {
  return props.alwaysOpen || open.value
})

onMounted(() => {
  if (anchor.value === route.hash && el.value) {
    open.value = true
    el.value.scrollIntoView()
  }
})

function onClick() {
  open.value = !open.value
}
</script>
