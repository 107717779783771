<template>
  <CkContent
    class="table-container"
    :class="{
      'has-mobile-style': hasMobileStyle,
      'is-sticky-left': !hasMobileStyle && sticky === 'left' && rowCount > 2,
      'is-sticky-top': !hasMobileStyle && sticky === 'top',
      'has-equalized-widths': equalizeWidths,
    }"
  >
    <table>
      <thead v-if="thead.length">
        <tr v-for="(row, i) in thead" :key="'thead_' + i">
          <StructuredTableCell
            v-for="(cell, j) in row"
            v-bind="cell"
            :key="'thead_cell_' + j"
          />
        </tr>
      </thead>

      <tbody v-if="tbody.length">
        <tr v-for="(row, i) in tbody" :key="'tbody_' + i">
          <StructuredTableCell
            v-for="(cell, j) in row"
            v-bind="cell"
            :key="'tbody_' + i + '_' + j"
            :tag="cell.tag"
            :content="cell.content"
            :attributes="getCellAttributes(cell, j)"
          />
        </tr>
      </tbody>

      <tfoot v-if="tfoot.length">
        <tr v-for="(row, i) in tbody" :key="'tfoot_' + i">
          <StructuredTableCell
            :is="cell.tag"
            v-for="(cell, j) in row"
            :key="'tfoot_' + i + '_' + j"
            v-bind="cell"
          />
        </tr>
      </tfoot>
    </table>
  </CkContent>
</template>

<script lang="ts" setup>
import type { StructuredTableCellFragment } from '#graphql-operations'
import { decodeEntities } from '~/helpers'

const props = defineProps<{
  mobileStyle: 'horizontal' | 'vertical'
  sticky: 'none' | 'left' | 'top'
  equalizeWidths?: boolean
  thead: StructuredTableCellFragment[][]
  tbody: StructuredTableCellFragment[][]
  tfoot: StructuredTableCellFragment[][]
  id?: string

  // This is not needed in this component, but it's part of the fragment.
  // We define it here so that v-bind can still be used when rendering this
  // component with GraphQL data.
  caption?: string
}>()

const hasMobileStyle = computed(() => props.mobileStyle === 'vertical')

function getCellAttributes(cell: StructuredTableCellFragment, index: number) {
  const attributes: Record<string, string> = cell.attributes || {}
  if (props.thead.length) {
    if (props.thead[0] !== undefined) {
      if (props.thead[0][index] !== undefined) {
        const label = (props.thead[0][index].content || '').trim()
        attributes['data-head-label'] = decodeEntities(
          label.replace(/<[^>]*>?/gm, '').replace(/\{(.*?)\}/, ' ($1)'),
        )
      }
    }
  }
  return attributes
}

const rowCount = computed(() => props.tbody[0]?.length)

defineOptions({
  name: 'StructuredTable',
})
</script>
