<template>
  <div
    :id="id"
    :class="{ container: !parentType }"
    class="my-30 first:mt-0 last:mb-0"
  >
    <div>
      <BlokkliField :list="paragraphs" name="field_paragraphs" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphLinkListFragment } from '#build/graphql-operations'

defineProps<{
  paragraphs?: ParagraphLinkListFragment['paragraphs']
}>()

const { parentType, options } = defineBlokkli({
  bundle: 'link_list',
  globalOptions: ['anchorId'],
  editor: {
    addBehaviour: 'no-form',
  },
})

const id = useAnchorId(options)

defineOptions({
  name: 'ParagraphLinkList',
})
</script>
