<template>
  <div class="relative">
    <AdminNodeEditInfo v-if="isEditing" />

    <div class="table-container my-24">
      <table class="is-fullwidth table">
        <thead>
          <tr>
            <th colspan="2">
              {{ $texts('pension_fund.interestRateAddonTable', 'Zinssätze') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in overviewRows" :key="row[0]">
            <td>{{ row[0] }}</td>
            <td class="!text-right">{{ row[1] }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <Accordion
      v-if="pastYearRates.length"
      class="mt-32"
      :always-open="isEditing"
      :title="
        $texts(
          'pension_fund.interestRatesOfPreviousYears',
          'Interest rates of previous years',
        )
      "
    >
      <div class="table-container">
        <table class="is-fullwidth table">
          <thead>
            <tr>
              <th>{{ $texts('pension_fund.year', 'Year') }}</th>
              <th>
                {{ $texts('pension_fund.pensionAssets', 'Altersguthaben') }}
              </th>
              <th>
                {{
                  $texts(
                    'pension_fund.bvgMinimumInterestRate',
                    'BVG minimum interest rate',
                  )
                }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, i) in pastYearRates" :key="row.year + i">
              <td>{{ row.year }}</td>
              <td>{{ row.ratePensionAssets }}</td>
              <td>{{ row.rateBvg }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Accordion>

    <Accordion
      v-if="currentYear"
      class="mt-32"
      :always-open="isEditing"
      :title="
        $texts(
          'pension_fund.interestRatesOtherTransactions',
          'Verzinsung der anderen Geschäftsvorfälle',
        )
      "
    >
      <div class="table-container">
        <table class="is-fullwidth table">
          <thead>
            <tr>
              <th>{{ $texts('pension_fund.interestRate', 'Interst rate') }}</th>
              <th class="!text-right">{{ currentYear.year }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, i) in currentYearRows" :key="i">
              <td>{{ row.label }}</td>
              <td class="!text-right">{{ row.value }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Accordion>
  </div>
</template>

<script lang="ts" setup>
import { notNullish } from '@vueuse/core'
import type {
  ParagraphInterestRateFragment,
  ParagraphYearlyInterestRatesFragment,
} from '#build/graphql-operations'

const { isEditing } = defineBlokkliFragment({
  name: 'pension_fund_interest_rates',
  label: 'Zinssätze',
  description: 'Zeigt die Tabelle und Akkordeons mit den Zinssätzen an.',
  editor: {
    previewWidth: 1200,
    previewBackgroundClass: 'bg-gray-100',
  },
})

type YearlyInterestRateRow = {
  label: string
  value: string
}

type PastYearsRate = {
  year: string
  rateBvg: string
  ratePensionAssets: string
}

const { $texts } = useEasyTexts()
const { formatPercentage } = useLocale()

const rates = inject<ComputedRef<
  ParagraphYearlyInterestRatesFragment[]
> | null>('node_pension_fund_interest_rates', null)

const currentYear = computed<ParagraphYearlyInterestRatesFragment | null>(
  () => {
    if (!rates) {
      return null
    }
    return rates.value[0] || null
  },
)

const currentYearRates = computed<ParagraphInterestRateFragment[]>(() => {
  return (
    currentYear.value?.referencedRates
      ?.map((v) => {
        if (v && 'value' in v) {
          return v
        }
        return null
      })
      .filter(notNullish) || []
  )
})

const currentYearRows = computed<YearlyInterestRateRow[]>(() => {
  return currentYearRates.value
    .map((v) => {
      if (v.type && 'name' in v.type) {
        return {
          label: v.type?.name || '',
          value: formatPercentage(v.value),
        }
      }
      return null
    })
    .filter(notNullish)
})

const pastYearRates = computed<PastYearsRate[]>(() => {
  if (!rates) {
    return []
  }
  return rates.value.slice(1).map((v) => {
    return {
      year: v.year?.toString() || '',
      rateBvg: formatPercentage(v.rateBvg),
      ratePensionAssets: formatPercentage(v.ratePensionAssets),
    }
  })
})

const overviewRows = computed(() => {
  const rows: string[][] = []

  const prevYear = pastYearRates.value[0]

  if (prevYear?.year && prevYear?.rateBvg) {
    rows.push([
      $texts(
        'pension_fund.interestRateAddonTableRetirementAssets',
        'Verzinsung des Altersguthabens @year',
      ).replace('@year', prevYear.year.toString()),
      formatPercentage(prevYear.ratePensionAssets),
    ])
  }

  if (currentYear.value?.year && currentYear.value.rateBvg) {
    rows.push([
      $texts(
        'pension_fund.interestRateAddonTableBvg',
        'BVG Mindestzins @year',
      ).replace('@year', currentYear.value.year.toString()),
      formatPercentage(currentYear.value.rateBvg),
    ])
  }

  return rows
})

defineOptions({
  name: 'BlokkliFragmentPensionFundInterestRates',
})
</script>
