<template>
  <section class="pt-0">
    <div v-if="terms" class="container">
      <GroupedList
        v-slot="{ item }"
        :items="terms"
        group-field="title"
        sort-field="title"
        class="mt-28"
        wrapper-class="grid gap-24"
      >
        <Accordion :title="item.title" :hash="'term-' + item.id">
          <div class="ck-content mobile-only:text-base" v-html="item.text" />
        </Accordion>
      </GroupedList>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { notNullish } from '@vueuse/core'
import type { GlossaryListTermFragment } from '#build/graphql-operations'

defineBlokkliFragment({
  name: 'glossary_list',
  label: 'Glossary List',
})

const { data: terms } = await useAsyncGraphqlQuery('glossaryList', null, {
  transform: function (data) {
    const termMap = (data.data.entityQuery.items || []).reduce<
      Record<string, GlossaryListTermFragment>
    >((acc, v) => {
      if (v && 'id' in v && v.id) {
        acc[v.id] = v
      }
      return acc
    }, {})

    return Object.values(termMap)
      .map((v) => {
        let description = v.description

        if (!description && v.parent?.first?.id) {
          description = termMap[v.parent.first.id]?.description
        }

        if (!description || !v.id || !v.label) {
          return null
        }

        return {
          id: v.id,
          title: v.label,
          text: description,
        }
      })
      .filter(notNullish)
  },
})
</script>
