<template>
  <Container>
    <ClientOnly>
      <Teaser
        v-if="employer"
        :title="employer.title"
        :to="employer.url?.path"
        :body="
          $texts(
            'dynamicContactSelectedDescription',
            'Kontaktieren Sie direkt die für Sie zuständige Ansprechpersonen.',
          )
        "
      />
    </ClientOnly>
    <form
      class="rounded-large bg-white p-20 shadow-lg not-first:hidden lg:p-24"
      @submit.prevent="onSubmit"
    >
      <h2 class="typo-h3 font-condensed font-bold">
        {{
          $texts('dynamicContactTitle', 'Persönliche Ansprechperson anrufen')
        }}
      </h2>
      <p class="mb-24 mt-20 text-balance text-xl">
        {{
          $texts(
            'dynamicContactDescription',
            'Sind Sie bei PUBLICA versichert? Wählen Sie Ihren Arbeitgeber aus um Ihre persönliche Ansprechperson anzurufen.',
          )
        }}
      </p>

      <SearchInput
        id="search-employer"
        v-model="searchText"
        :label="$texts('default.searchEmployer', 'Arbeitgeber suchen')"
      />
    </form>
  </Container>
</template>

<script lang="ts" setup>
const { employer } = useVisitorState()

defineBlokkliFragment({
  name: 'dynamic_contact',
  label: 'Dynamic Contact',
})

const searchText = ref('')

const router = useRouter()

function onSubmit() {
  router.push({
    name: 'choose-employer',
    query: {
      text: searchText.value,
    },
  })
}
</script>
