
    import { defineAsyncComponent } from '#imports'
    
import BlokkliComponent_savings_contribution_index from '/builds/bazinga/publica/frontend/components/Paragraph/SavingsContribution/index.vue'
import BlokkliComponent_savings_contribution_entry_index from '/builds/bazinga/publica/frontend/components/Paragraph/SavingsContributionEntry/index.vue'
import BlokkliComponent_from_library_index from '/builds/bazinga/publica/frontend/node_modules/@blokkli/editor/dist/runtime/components/Blocks/FromLibrary/index.vue'
import BlokkliComponent_accordion_index from '/builds/bazinga/publica/frontend/components/Paragraph/Accordion/index.vue'
import BlokkliComponent_card_index from '/builds/bazinga/publica/frontend/components/Paragraph/Card/index.vue'
import BlokkliComponent_container_index from '/builds/bazinga/publica/frontend/components/Paragraph/Container/index.vue'
import BlokkliComponent_file_InsideGrid from '/builds/bazinga/publica/frontend/components/Paragraph/File/InsideGrid.vue'
import BlokkliComponent_file_index from '/builds/bazinga/publica/frontend/components/Paragraph/File/index.vue'
import BlokkliComponent_flourish_index from '/builds/bazinga/publica/frontend/components/Paragraph/Flourish/index.vue'
import BlokkliComponent_gallery_index from '/builds/bazinga/publica/frontend/components/Paragraph/Gallery/index.vue'
import BlokkliComponent_glossary_index from '/builds/bazinga/publica/frontend/components/Paragraph/Glossary/index.vue'
import BlokkliComponent_grid_index from '/builds/bazinga/publica/frontend/components/Paragraph/Grid/index.vue'
import BlokkliComponent_image_InsideGallery from '/builds/bazinga/publica/frontend/components/Paragraph/Image/InsideGallery.vue'
import BlokkliComponent_image_index from '/builds/bazinga/publica/frontend/components/Paragraph/Image/index.vue'
import BlokkliComponent_link_index from '/builds/bazinga/publica/frontend/components/Paragraph/Link/index.vue'
import BlokkliComponent_link_list_index from '/builds/bazinga/publica/frontend/components/Paragraph/LinkList/index.vue'
import BlokkliComponent_nav_section_index from '/builds/bazinga/publica/frontend/components/Paragraph/NavSection/index.vue'
import BlokkliComponent_parity_commission_member_index from '/builds/bazinga/publica/frontend/components/Paragraph/ParityCommissionMember/index.vue'
import BlokkliComponent_person_index from '/builds/bazinga/publica/frontend/components/Paragraph/Person/index.vue'
import BlokkliComponent_person_list_index from '/builds/bazinga/publica/frontend/components/Paragraph/PersonList/index.vue'
import BlokkliComponent_quote_index from '/builds/bazinga/publica/frontend/components/Paragraph/Quote/index.vue'
import BlokkliComponent_remote_video_index from '/builds/bazinga/publica/frontend/components/Paragraph/RemoteVideo/index.vue'
import BlokkliComponent_table_index from '/builds/bazinga/publica/frontend/components/Paragraph/Table/index.vue'
import BlokkliComponent_text_index from '/builds/bazinga/publica/frontend/components/Paragraph/Text/index.vue'
import BlokkliComponent_title_InsideNavSectionHeader from '/builds/bazinga/publica/frontend/components/Paragraph/Title/InsideNavSectionHeader.vue'
import BlokkliComponent_title_index from '/builds/bazinga/publica/frontend/components/Paragraph/Title/index.vue'
import BlokkliComponent_blokkli_fragment_index from '/builds/bazinga/publica/frontend/node_modules/@blokkli/editor/dist/runtime/components/Blocks/Fragment/index.vue'

const global: Record<string, any> = {
  block_savings_contribution: BlokkliComponent_savings_contribution_index,
  block_savings_contribution_entry: BlokkliComponent_savings_contribution_entry_index,
  block_from_library: BlokkliComponent_from_library_index,
  block_accordion: BlokkliComponent_accordion_index,
  block_card: BlokkliComponent_card_index,
  block_container: BlokkliComponent_container_index,
  block_file__parent_block_grid: BlokkliComponent_file_InsideGrid,
  block_file: BlokkliComponent_file_index,
  block_flourish: BlokkliComponent_flourish_index,
  block_gallery: BlokkliComponent_gallery_index,
  block_glossary: BlokkliComponent_glossary_index,
  block_grid: BlokkliComponent_grid_index,
  block_image__parent_block_gallery: BlokkliComponent_image_InsideGallery,
  block_image: BlokkliComponent_image_index,
  block_link: BlokkliComponent_link_index,
  block_link_list: BlokkliComponent_link_list_index,
  block_nav_section: BlokkliComponent_nav_section_index,
  block_parity_commission_member: BlokkliComponent_parity_commission_member_index,
  block_person: BlokkliComponent_person_index,
  block_person_list: BlokkliComponent_person_list_index,
  block_quote: BlokkliComponent_quote_index,
  block_remote_video: BlokkliComponent_remote_video_index,
  block_table: BlokkliComponent_table_index,
  block_text: BlokkliComponent_text_index,
  block_title__field_list_type_nav_title: BlokkliComponent_title_InsideNavSectionHeader,
  block_title: BlokkliComponent_title_index,
  block_blokkli_fragment: BlokkliComponent_blokkli_fragment_index
}

    
import BlokkliFragmentComponent_current_funded_ratio from '/builds/bazinga/publica/frontend/components/Paragraph/BlokkliFragment/CurrentFundedRatio/index.vue'
import BlokkliFragmentComponent_dynamic_contact from '/builds/bazinga/publica/frontend/components/Paragraph/BlokkliFragment/DynamicContact/index.vue'
import BlokkliFragmentComponent_glossary_list from '/builds/bazinga/publica/frontend/components/Paragraph/BlokkliFragment/GlossaryList/index.vue'
import BlokkliFragmentComponent_in_page_nav from '/builds/bazinga/publica/frontend/components/Paragraph/BlokkliFragment/InPageNav/index.vue'
import BlokkliFragmentComponent_mailxpert from '/builds/bazinga/publica/frontend/components/Paragraph/BlokkliFragment/Mailxpert/index.vue'
import BlokkliFragmentComponent_menu_list from '/builds/bazinga/publica/frontend/components/Paragraph/BlokkliFragment/MenuList/index.vue'
import BlokkliFragmentComponent_news_list from '/builds/bazinga/publica/frontend/components/Paragraph/BlokkliFragment/NewsList/index.vue'
import BlokkliFragmentComponent_parity_commission_tenure from '/builds/bazinga/publica/frontend/components/Paragraph/BlokkliFragment/ParityCommissionTenure/index.vue'
import BlokkliFragmentComponent_pension_fund_interest_rates from '/builds/bazinga/publica/frontend/components/Paragraph/BlokkliFragment/PensionFundInterestRates/index.vue'
import BlokkliFragmentComponent_person_list from '/builds/bazinga/publica/frontend/components/Paragraph/BlokkliFragment/PersonList/index.vue'
import BlokkliFragmentComponent_voting from '/builds/bazinga/publica/frontend/components/Paragraph/BlokkliFragment/Voting/index.vue'

const globalFragments: Record<string, any> = {
  fragment_current_funded_ratio: BlokkliFragmentComponent_current_funded_ratio,
  fragment_dynamic_contact: BlokkliFragmentComponent_dynamic_contact,
  fragment_glossary_list: BlokkliFragmentComponent_glossary_list,
  fragment_in_page_nav: BlokkliFragmentComponent_in_page_nav,
  fragment_mailxpert: BlokkliFragmentComponent_mailxpert,
  fragment_menu_list: BlokkliFragmentComponent_menu_list,
  fragment_news_list: BlokkliFragmentComponent_news_list,
  fragment_parity_commission_tenure: BlokkliFragmentComponent_parity_commission_tenure,
  fragment_pension_fund_interest_rates: BlokkliFragmentComponent_pension_fund_interest_rates,
  fragment_person_list: BlokkliFragmentComponent_person_list,
  fragment_voting: BlokkliFragmentComponent_voting
}


const chunks: Record<string, () => Promise<any>> = {
  rare: () => import('#blokkli/chunk-rare')
}

const chunkMapping: Record<string, string> = {}

const fragmentChunkMapping: Record<string, string> = {}

export function getBlokkliItemComponent(bundle: string, fieldListType?: string, parentBundle?: string): any {
  const forFieldListType = 'block_' + bundle + '__field_list_type_' + fieldListType
  if (global[forFieldListType]) {
    return global[forFieldListType]
  }
  if (parentBundle) {
    const forParentBundle = 'block_' + bundle + '__parent_block_' + parentBundle
    if (global[forParentBundle]) {
      return global[forParentBundle]
    }
  }
  const key = 'block_' + bundle
  if (global[key]) {
    return global[key]
  }
  const chunkName = chunkMapping[key]
  if (chunkName) {
    return defineAsyncComponent(() => chunks[chunkName]().then(chunk => {
      return chunk.default[key]
    }))
  }
}

export function getBlokkliFragmentComponent(name: string): any {
  const key = 'fragment_' + name
  if (globalFragments[key]) {
    return globalFragments[key]
  }
  const chunkName = fragmentChunkMapping[key]
  if (chunkName) {
    return defineAsyncComponent(() => chunks[chunkName]().then(chunk => {
      return chunk.default[key]
    }))
  }
}
