<template>
  <div
    v-if="body || isEditing"
    :id="id"
    class="not-last:mb-24 mt-16 md:mt-24"
    :class="{ container: !parentType || parentType === 'container' }"
  >
    <CkContent>
      <div
        v-blokkli-editable:field_body
        class="ck-content hyphens-auto lg:hyphens-none"
        :class="{
          'col-span-4 sm:col-span-6 md:col-span-6 md:col-start-2 lg:col-span-8 lg:col-start-3':
            !parentType,
          'text-white': background === 'highlighted',
        }"
        v-html="body"
      />
    </CkContent>
  </div>
</template>

<script lang="ts" setup>
defineProps<{ body?: string }>()

const { parentType, isEditing, options } = defineBlokkli({
  bundle: 'text',
  editor: {
    addBehaviour: 'no-form',
    editTitle: (el) => el.textContent,
    getDraggableElement: (el) => el.querySelector('.ck-content'),
  },
  globalOptions: ['anchorId'],
})

const { background } = useParagraphContext()
const id = useAnchorId(options)

defineOptions({
  name: 'ParagraphText',
})
</script>
