<template>
  <div
    :id="id"
    :class="{
      'is-wide container': isWideTeaser,
      container: needsContainer,
      'my-24 first:mt-0 last:mb-0': !isTeaser,
    }"
  >
    <OverlayedTeaser v-if="isWideTeaser" :media="media">
      <Teaser :title="title" :body="body" :to="link?.uri?.path" />
    </OverlayedTeaser>
    <Teaser
      v-else-if="parentType === 'grid'"
      :title="title"
      :body="body"
      :media="media"
      :to="link?.uri?.path"
    />
    <Teaser
      v-else
      :title="title"
      :body="body"
      :media="media"
      :to="link?.uri?.path"
      inline-icon
    />
  </div>
</template>

<script lang="ts" setup>
import type {
  FieldItemTypeLinkEntityTeaserFragment,
  MediaIconFragment,
  MediaImageFragment,
  ParagraphCardFragment,
} from '#graphql-operations'

const props = defineProps<{
  titleOverride?: string
  bodyOverride?: string
  link?: ParagraphCardFragment['link']
  mediaOverride?: ParagraphCardFragment['mediaOverride']
}>()

const { options, parentType } = defineBlokkli({
  bundle: 'card',
  globalOptions: ['anchorId'],
  options: {
    isWide: {
      type: 'checkbox',
      label: 'Breit',
      default: false,
    },
    showImage: {
      type: 'checkbox',
      label: 'Bild anzeigen',
      default: true,
    },
  },
  editor: {
    editTitle: (el) => el.querySelector('h2')?.textContent,
  },
})

const id = useAnchorId(options)

const isTeaser = computed(() => parentType.value === 'grid')

const needsContainer = computed(
  () => !parentType.value || parentType.value === 'container',
)

const entity = computed<FieldItemTypeLinkEntityTeaserFragment | null>(() => {
  if (props.link?.uri && 'entity' in props.link.uri && props.link.uri.entity) {
    return props.link.uri.entity
  }
  return null
})

const media = computed<MediaIconFragment | MediaImageFragment | undefined>(
  () => {
    if (!options.value.showImage) {
      return
    }
    if (
      props.mediaOverride &&
      ('name' in props.mediaOverride || 'svg' in props.mediaOverride)
    ) {
      return props.mediaOverride
    } else if (
      entity.value &&
      'teaserImage' in entity.value &&
      entity.value.teaserImage
    ) {
      return entity.value.teaserImage
    }

    return undefined
  },
)

const title = computed<string>(() => {
  if (props.titleOverride) {
    return props.titleOverride
  }
  return entity.value?.label || ''
})

const body = computed<string>(() => {
  if (props.bodyOverride) {
    return props.bodyOverride
  } else if (entity.value && 'intro' in entity.value && entity.value.intro) {
    return entity.value.intro
  }

  return ''
})

const isWideTeaser = computed(() => {
  return (
    options.value.isWide &&
    parentType.value !== 'grid' &&
    parentType.value !== 'nav_section'
  )
})

defineOptions({
  name: 'ParagraphCard',
})
</script>
