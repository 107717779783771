<template>
  <div class="my-16 md:my-24" :class="{ container: !parentType }">
    <Accordion
      :title="title || $texts('teaserCta', 'Mehr erfahren')"
      :always-open="isEditing"
      :hash="id"
    >
      <BlokkliField :list="paragraphs" name="field_paragraphs" />
    </Accordion>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphAccordionFragment } from '#build/graphql-operations'

const props = defineProps<{
  title?: string
  paragraphs?: ParagraphAccordionFragment['paragraphs']
}>()

const { parentType, isEditing, options } = defineBlokkli({
  bundle: 'accordion',
  globalOptions: ['anchorId'],
  editor: {
    addBehaviour: 'no-form',
  },
})
const id = useAnchorId(options, props)

defineOptions({
  name: 'ParagraphAccordion',
})
</script>
