<template>
  <div class="relative">
    <AdminNodeEditInfo v-if="isEditing" />

    <Teaser
      :title="
        $texts('pension_fund.currentFundedRatioTitle', 'Aktueller Deckungsgrad')
      "
      :body="body"
      symbol="info-empty"
      inline-icon
    />

    <Accordion
      v-if="ratios.length"
      class="mt-32"
      :always-open="isEditing"
      :title="
        $texts(
          'pension_fund.fundedRatioDevelopmentTitle',
          'Entwicklung des Deckungsgrades',
        )
      "
    >
      <div class="table-container">
        <table class="is-fullwidth table">
          <thead>
            <tr>
              <th>{{ $texts('date', 'Datum') }}</th>
              <th>
                {{
                  $texts(
                    'pension_fund.regulatoryFundRatio',
                    'Regulatorischer Deckungsgrad',
                  )
                }}
              </th>
              <th>
                {{
                  $texts(
                    'pension_fund.economicFundRatio',
                    'Ökonomischer Deckungsgrad',
                  )
                }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, i) in ratios" :key="i">
              <td>{{ row.date?.formatted }}</td>
              <td>{{ formatPercentage(row.regulatory) }}</td>
              <td>{{ formatPercentage(row.economic) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Accordion>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphFundRatioFragment } from '#build/graphql-operations'

const { isEditing } = defineBlokkliFragment({
  name: 'current_funded_ratio',
  label: 'Deckungsgrade',
  description:
    'Zeig den aktuellen Deckungsgrad und die «Entwicklung des Deckungsgrades» an.',
  editor: {
    previewWidth: 1200,
    previewBackgroundClass: 'bg-gray-100',
  },
})

const { $texts } = useEasyTexts()
const { formatPercentage } = useLocale()

const injectedRatios = inject<ComputedRef<ParagraphFundRatioFragment[]> | null>(
  'node_pension_fund_ratios',
  null,
)

const ratios = computed<ParagraphFundRatioFragment[]>(() =>
  [...(injectedRatios?.value || [])].sort((a, b) => {
    // Always sort by date so that the newest is first.
    const ta = a.date?.value
    const tb = b.date?.value
    if (ta && tb) {
      return parseInt(tb) - parseInt(ta)
    }

    return 0
  }),
)

const body = computed(() => {
  const firstDate = ratios.value[0]?.date?.formatted
  const firstRatio = ratios.value[0]?.regulatory
  if (!firstDate || !firstRatio) {
    return
  }

  return $texts(
    'pension_fund.currentFundedRatioText',
    'Der regulatorische Deckungsgrad per @date beträgt @ratio.',
  )
    .replace('@date', firstDate)
    .replace('@ratio', formatPercentage(firstRatio))
})
</script>
