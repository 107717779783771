<template>
  <LinkListItem
    v-if="parentType === 'link_list' && to"
    :to="to"
    :title="title"
  />
  <div v-else-if="to" class="is-wide container my-32 md:my-64">
    <OverlayedTeaser>
      <template #image>
        <div
          class="flex aspect-4/3 items-center justify-center rounded-large bg-gray-100"
        >
          <div
            class="size-[200px] md:size-[360px] flex items-center justify-center pb-48 md:pb-0"
          >
            <RokkaImage
              v-if="hash"
              :config="imageStyle"
              :hash="hash"
              class="shadow-lg max-h-full"
            />
          </div>
        </div>
      </template>

      <div>
        <Teaser :title="title" :to="to" />
      </div>
    </OverlayedTeaser>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphFileFragment } from '#graphql-operations'

const props = defineProps<{
  media?: ParagraphFileFragment['media']
}>()

const { parentType } = defineBlokkli({
  bundle: 'file',
})

const to = computed(() => props.media?.mediaUrl?.path)
const title = computed(() => props.media?.name || '')

const imageStyle = defineImageStyle({
  type: 'sizes',
  sizes: {
    sm: 400,
  },
})

const hash = computed(() => props.media?.file?.entity?.rokkaMetadata?.hash)

defineOptions({
  name: 'ParagraphFile',
})
</script>
