<template>
  <Container class="my-24 lg:my-40">
    <div ref="gallery">
      <BlokkliField
        name="field_paragraphs"
        class="paragraph-gallery-inner grid grid-cols-3 gap-8 lg:gap-24"
        :list="paragraphs"
        drop-alignment="horizontal"
      />
    </div>
  </Container>
</template>

<script lang="ts" setup>
import Lightbox from 'photoswipe/lightbox'
import type { ParagraphGalleryFragment } from '#graphql-operations'
import 'photoswipe/dist/photoswipe.css'

defineProps<{
  paragraphs?: ParagraphGalleryFragment['paragraphs']
}>()

const { isEditing } = defineBlokkli({
  bundle: 'gallery',
  editor: {
    addBehaviour: 'no-form',
  },
})

const gallery = ref<HTMLDivElement | null>(null)

let lightbox: Lightbox | null = null

onMounted(() => {
  if (isEditing) {
    return
  }
  if (!gallery.value) {
    return
  }
  lightbox = new Lightbox({
    gallery: gallery.value,
    children: 'a',
    pswpModule: () => import('photoswipe'),
    showHideAnimationType: 'zoom',
  })
  // parse data-google-map-url attribute
  lightbox.addFilter('itemData', (itemData) => {
    const vimeoUrl = itemData.element?.getAttribute('href')
    if (vimeoUrl) {
      itemData.vimeoUrl = vimeoUrl
    }
    return itemData
  })

  // override slide content
  lightbox.init()
})

onBeforeUnmount(() => {
  if (lightbox) {
    lightbox.destroy()
  }
})

defineOptions({
  name: 'ParagraphGallery',
})
</script>

<style lang="postcss">
.paragraph-gallery-inner {
  grid-auto-rows: 240px;
  grid-auto-flow: dense;
}
</style>
