<template>
  <div class="container my-16 md:my-24 lg:my-48">
    <div
      v-blokkli-droppable:field_media_image
      class="overflow-hidden rounded-large"
    >
      <MediaImage v-bind="image" :image-style="imageStyle" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphImageFragment } from '#graphql-operations'

defineProps<{
  image?: ParagraphImageFragment['image']
}>()

const { options } = defineBlokkli({
  bundle: 'image',
  options: {
    aspectRatio: {
      type: 'radios',
      label: 'Bildverhältnis',
      default: 'original',
      options: {
        original: 'Original',
        '2_3': '2/3',
        '16_9': '16/9',
      },
    },
  },
  editor: {
    editTitle: (el) => {
      const img = el.querySelector('img')
      return img?.alt || img?.title
    },
    getDraggableElement: (el) => el.querySelector('figure'),
  },
})

const aspectRatio = computed(() => {
  if (options.value.aspectRatio === '2_3') {
    return 2 / 3
  } else if (options.value.aspectRatio === '16_9') {
    return 16 / 9
  }
  return undefined
})

const imageStyle = computed(() => {
  return defineImageStyle({
    type: 'sizes',
    aspectRatio: aspectRatio.value,
    sizes: {
      xs: 770,
      sm: 984,
      md: 1380,
      lg: 1380,
    },
  })
})

defineOptions({
  name: 'ParagraphImage',
})
</script>
