<template>
  <div class="is-wide container my-24 lg:my-40 xl:my-56">
    <OverlayedTeaser :media="person?.image" class="my-64 first:mt-0 last:mb-0">
      <PersonCard
        v-bind="person"
        class="rounded-large bg-white p-30 shadow-elevated"
      />
    </OverlayedTeaser>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphPersonFragment } from '#graphql-operations'

defineProps<{
  person?: ParagraphPersonFragment['person']
}>()

defineBlokkli({
  bundle: 'person',
  editor: {
    editTitle: (el) => el.querySelector('h2')?.textContent,
  },
})

defineOptions({
  name: 'ParagraphPerson',
})
</script>
