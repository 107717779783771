<template>
  <div>
    <Teaser :title="title" :to="to">
      <template #image>
        <div
          class="flex aspect-4/3 items-center justify-center rounded-large bg-gray-100"
        >
          <RokkaImage
            v-if="hash"
            :config="imageStyle"
            :hash="hash"
            class="w-[160px] shadow-lg"
          />
        </div>
      </template>
    </Teaser>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphFileFragment } from '#graphql-operations'

const props = defineProps<{
  media?: ParagraphFileFragment['media']
}>()

defineBlokkli({
  bundle: 'file',
  renderFor: [
    {
      parentBundle: 'grid',
    },
  ],
})

const to = computed(() => props.media?.mediaUrl?.path)
const title = computed(() => props.media?.name)

const imageStyle = defineImageStyle({
  type: 'sizes',
  sizes: {
    sm: 400,
  },
})

const hash = computed(() => props.media?.file?.entity?.rokkaMetadata?.hash)

defineOptions({
  name: 'ParagraphFile',
})
</script>
