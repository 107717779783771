<template>
  <div class="ck-content">
    <p>{{ body }}</p>
  </div>
</template>

<script lang="ts" setup>
const { options } = defineBlokkliFragment({
  name: 'parity_commission_tenure',
  label: 'Amtsdauer',
  description: 'Zeigt die Amtsdauer des paritätischen Organs an.',
  options: {
    dateFrom: {
      type: 'text',
      inputType: 'date',
      label: 'Datum von',
      default: '2024-09-25',
    },
    dateTo: {
      type: 'text',
      inputType: 'date',
      label: 'Datum bis',
      default: '2024-10-25',
    },
  },
})

const { $texts } = useEasyTexts()
const { formatDate } = useLocale()

const dateFrom = computed(() => formatDate(new Date(options.value.dateFrom)))

const dateTo = computed(() => formatDate(new Date(options.value.dateTo)))

const body = computed(() => {
  return $texts(
    'pension_fund.parityCommissionTenure',
    'Für die Amtsdauer vom @dateFrom bis @dateTo wurden folgende Personen für das paritätische Organ ernannt:',
  )
    .replace('@dateFrom', dateFrom.value)
    .replace('@dateTo', dateTo.value)
})
</script>
