<template>
  <div class="table-container my-24">
    <table>
      <caption v-if="title" v-html="title" />
      <BlokkliField
        v-slot="{ items }"
        name="field_entries"
        :list="paragraphs"
        tag="tbody"
      >
        <ParagraphSavingsContributionHeader
          :type="options.interestRateType"
          :overrides="tableHeaders"
          :items="items"
        />
      </BlokkliField>
    </table>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphSavingsContributionFragment } from '#graphql-operations'

const { options } = defineBlokkli({
  bundle: 'savings_contribution',
  options: {
    interestRateType: {
      type: 'radios',
      label: 'Tabellentyp',
      options: {
        default: 'Standard',
        voluntary: 'Freiwillig',
      },
      default: 'default',
    },
  },
})

defineProps<{
  title: string
  tableHeaders?: string[]
  paragraphs: ParagraphSavingsContributionFragment['paragraphs']
}>()

defineOptions({
  name: 'ParagraphSavingsContribution',
})

const interestRateType = computed(() => options.value.interestRateType)
provide('interest_rate_type', interestRateType)
</script>

<style lang="postcss">
.savings-contribution + .savings-contribution {
  margin-top: calc(var(--section-gap-v) * 0.5);
}

.savings-contribution {
  .table {
    th,
    td {
      &:nth-child(n + 2) {
        text-align: right;
      }
    }
  }
}
</style>
