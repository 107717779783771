<template>
  <div
    :class="{
      'is-wide container mt-16 md:mt-32 lg:mt-56':
        !parentType || parentType === 'container',
      'mobile-only:!p-0': options.scrollableOnMobile,
    }"
  >
    <div
      class="paragraph-grid"
      :class="{ 'is-scrollable': options.scrollableOnMobile }"
    >
      <BlokkliField
        :list="paragraphs"
        name="field_paragraphs"
        class="teaser-grid"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphContainerFragment } from '#graphql-operations'

defineProps<{ paragraphs?: ParagraphContainerFragment['paragraphs'] }>()

const { parentType, options } = defineBlokkli({
  bundle: 'grid',
  editor: {
    addBehaviour: 'no-form',
  },
  options: {
    scrollableOnMobile: {
      type: 'checkbox',
      label: 'Scrollbar auf Mobile',
      default: false,
    },
  },
})

defineOptions({
  name: 'ParagraphGrid',
})
</script>

<style lang="postcss">
.paragraph-grid {
  &.is-scrollable {
    @apply mobile-only:overflow-hidden;
    .teaser-grid {
      @apply mobile-only:flex mobile-only:overflow-auto mobile-only:pb-20;
      @apply mobile-only:snap-x mobile-only:snap-proximity;

      > div {
        @apply mobile-only:w-[270px] mobile-only:flex-none mobile-only:snap-center mobile-only:first:pl-16 mobile-only:last:pr-16;
      }
    }
  }
}
</style>
