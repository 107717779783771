<template>
  <section class="section pt-0 my-24">
    <div class="container">
      <div v-for="group in groups" :key="group.year" class="news-list">
        <h2 class="title is-4">{{ group.year }}</h2>
        <div class="news-list-items">
          <div
            v-for="item in group.items"
            :key="item.nid"
            class="teaser-listable"
          >
            <teaser
              :title="item.title"
              :to="item.to"
              :tagline="item.tagline"
              :body="item.lead"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
type NewsListGroupItem = {
  nid: string | number | undefined
  to: string
  title: string
  lead: string
  tagline: string
  timestamp: number
}

defineBlokkliFragment({
  name: 'news_list',
  label: 'All News',
})

const { data: groups } = await useAsyncGraphqlQuery('newsList', null, {
  transform: function (data) {
    const items = (data.data.entityQuery.items || []).filter((v) => {
      return v && v.__typename === 'NodeNews'
    })

    const map = items.reduce<Record<string, NewsListGroupItem[]>>(
      (acc, item) => {
        const year = item.year?.first?.formatted || ''
        const date = item.date?.first?.formatted || ''
        if (!acc[year]) {
          acc[year] = []
        }
        const topic = item.referencedTopic?.title || ''

        acc[year].push({
          nid: item.id,
          to: item.url?.path || '',
          title: item.title || '',
          lead: item.intro || '',
          tagline: [date, topic].filter(Boolean).join(' - '),
          timestamp: parseInt(item.timestamp?.first?.formatted || '') || 0,
        })
        return acc
      },
      {},
    )

    return Object.keys(map)
      .sort((a, b) => {
        return parseInt(b) - parseInt(a)
      })
      .map((year) => {
        return {
          year,
          items: map[year].sort((a, b) => {
            return b.timestamp - a.timestamp
          }),
        }
      })
  },
})
</script>

<style lang="postcss">
.news-list-items {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--grid-gap);
}

.news-list + .news-list {
  margin-top: calc(var(--grid-gap) * 3);
}
</style>
