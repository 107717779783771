<template>
  <Container class="mb-20">
    <Accordion v-if="term?.label" :title="term.label">
      <div class="ck-content" v-html="term.description" />
    </Accordion>
  </Container>
</template>

<script lang="ts" setup>
import type { ParagraphGlossaryFragment } from '#graphql-operations'

defineProps<{
  term?: ParagraphGlossaryFragment['term']
}>()

defineBlokkli({
  bundle: 'glossary',
})

defineOptions({
  name: 'ParagraphGlossary',
})
</script>
