<template>
  <Container>
    <figure
      v-if="structuredTable || isEditing"
      :id="id"
      class="mt-16 first:mt-0 lg:mt-36"
    >
      <figcaption
        v-if="structuredTable?.caption && !options.hideCaption"
        class="mb-10 text-lg font-bold lg:mb-32 lg:text-2xl"
        v-html="structuredTable.caption"
      />
      <StructuredTable
        v-if="structuredTable"
        v-bind="structuredTable"
        :id="uuid"
        :mobile-style="options.mobileStyle"
        :sticky="options.sticky"
        :equalize-widths="options.equalizeWidths"
      />
    </figure>
  </Container>
</template>

<script lang="ts" setup>
import type { ParagraphTableFragment } from '#graphql-operations'

const { options, uuid, isEditing } = defineBlokkli({
  bundle: 'table',
  globalOptions: ['anchorId'],
  options: {
    hideCaption: {
      type: 'checkbox',
      label: 'Beschriftung verstecken',
      default: false,
    },
    equalizeWidths: {
      type: 'checkbox',
      label: 'Alle Spalten gleich breit',
      default: true,
    },
    mobileStyle: {
      type: 'radios',
      label: 'Mobile Darstellung',
      default: 'horizontal',
      displayAs: 'icons',
      options: {
        vertical: {
          label: 'Vertikal',
          icon: 'icon-blokkli-option-table-vertical',
        },
        horizontal: {
          label: 'Horizontal',
          icon: 'icon-blokkli-option-table-horizontal',
        },
      },
    },
    sticky: {
      type: 'radios',
      label: 'Sticky',
      default: 'left',
      options: {
        none: '-',
        left: 'Links',
        top: 'Oben',
      },
    },
  },
  editor: {
    editTitle: (el) => el.querySelector('figcaption')?.innerText,
    determineVisibleOptions: (ctx) => {
      if (ctx.options.mobileStyle === 'vertical') {
        return ['equalizeWidths', 'mobileStyle']
      }

      return ['equalizeWidths', 'mobileStyle', 'sticky']
    },
  },
})

const id = useAnchorId(options)

defineProps<{
  structuredTable?: ParagraphTableFragment['structuredTable']
}>()

defineOptions({
  name: 'ParagraphTable',
})
</script>
