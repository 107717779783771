<template>
  <tr>
    <td class="whitespace-nowrap">{{ givenName }} {{ familyName }}</td>
    <td>{{ employer?.title }}</td>
    <td class="whitespace-nowrap">{{ functionLabel }}</td>
    <td class="w-16 !text-right !align-middle">
      <div class="flex items-center justify-end gap-8">
        <a v-if="phone?.first?.parsed" :href="phone.first.parsed.rfc3966">
          <SpriteSymbol name="phone" class="size-16" />
        </a>

        <a v-if="email" :href="'mailto:' + email">
          <SpriteSymbol name="mail" class="size-16" />
        </a>
      </div>
    </td>
  </tr>
</template>

<script setup lang="ts">
import type { ParagraphParityCommissionMemberFragment } from '#graphql-operations'

defineBlokkli({
  bundle: 'parity_commission_member',
})

const props = defineProps<{
  addressLine?: string
  email?: string
  employer?: ParagraphParityCommissionMemberFragment['employer']
  familyName?: string
  givenName?: string
  functionText?: string
  phone?: ParagraphParityCommissionMemberFragment['phone']
  representation?: string
}>()

defineOptions({
  name: 'ParagraphParityCommissionMember',
})

const { $texts } = useEasyTexts()

const functionLabel = computed(() => {
  if (props.functionText === 'vice_president') {
    return $texts('pension_fund.vicePresident', 'Vice President')
  } else if (props.functionText === 'vice_president_f') {
    return $texts('pension_fund.vicePresidentFemale', 'Vice President')
  } else if (props.functionText === 'president') {
    return $texts('pension_fund.president', 'President')
  } else if (props.functionText === 'president_f') {
    return $texts('pension_fund.presidentFemale', 'President')
  } else if (props.functionText === 'member') {
    return $texts('pension_fund.member', 'Member')
  }
  return ''
})
</script>
