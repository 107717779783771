import { slugify } from '#vuepal/helpers/url'

export default function (
  options: ComputedRef<{ anchorId?: string }>,
  props?: { title?: string },
) {
  return computed(() => {
    if (options.value.anchorId) {
      return slugify(options.value.anchorId)
    } else if (props?.title) {
      return slugify(props.title)
    }
  })
}
