<template>
  <Container class="my-24">
    <iframe
      ref="iframe"
      :src="src"
      :title="$texts('paragraphFlourishTitle', 'Visualisation')"
      :style="{
        height: height + 'px',
      }"
      class="w-full"
      :class="{
        'pointer-events-none': isEditing,
      }"
    />
  </Container>
</template>

<script lang="ts" setup>
import { useRafFn } from '@vueuse/core'

const props = defineProps<{
  source?: string
}>()

const { isEditing } = defineBlokkli({
  bundle: 'flourish',
})

const src = computed(() => '/render/flourish?source=' + props.source)

const iframe = ref<HTMLIFrameElement | null>(null)

const height = ref(400)

useRafFn(() => {
  if (!iframe.value) {
    return
  }

  const newHeight = iframe.value.contentDocument?.documentElement?.scrollHeight

  if (!newHeight) {
    return
  }

  if (height.value === newHeight) {
    return
  }

  height.value = newHeight
})

defineOptions({
  name: 'ParagraphFlourish',
})
</script>
