<template>
  <div v-if="items" class="section bg-gray-100">
    <div class="is-wide container">
      <div class="teaser-grid">
        <div v-for="(link, i) in items" :key="i" class="teaser-listable">
          <Teaser :title="link.link.label" :to="link.link.url?.path" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type {
  MainMenuLinkTreeFirstFragment,
  MainMenuLinkTreeSecondFragment,
  MainMenuLinkTreeThirdFragment,
} from '#build/graphql-operations'

defineBlokkliFragment({
  name: 'menu_list',
  label: 'Menu List',
})

const data = await useInitData()
const route = useRoute()

type MainMenuLinkTree =
  | MainMenuLinkTreeFirstFragment
  | MainMenuLinkTreeSecondFragment
  | MainMenuLinkTreeThirdFragment

function findMatch(links: MainMenuLinkTree[]): MainMenuLinkTree[] | null {
  for (let i = 0; i < links.length; i++) {
    const link = links[i]
    if ('links' in link && link.links) {
      if (link.link.url?.path === route.path) {
        return link.links
      }

      const match = findMatch(link.links)
      if (match) {
        return match
      }
    }
  }

  return null
}

const items = computed(() => findMatch(data.value.mainMenuLinks))
</script>
