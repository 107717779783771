<template>
  <DrupalLink
    :to="to"
    class="focus:focusable group flex h-full min-h-170 flex-col rounded-large bg-white p-20 lg:p-24 xl:p-28"
    :class="[
      {
        'transition hover:shadow': to,
      },
      to ? 'shadow-lowered' : 'shadow',
    ]"
  >
    <div v-if="(icon || symbol) && inlineIcon" class="mb-32">
      <MediaIcon v-if="icon" v-bind="icon" class="size-64 text-blue-400" />
      <SpriteSymbol
        v-else-if="symbol"
        :name="symbol"
        class="size-64 text-blue-400"
      />
    </div>
    <div v-else-if="$slots.image || media || symbol" class="mb-16">
      <slot name="image">
        <div v-if="media" class="aspect-[4/3] overflow-hidden rounded-large">
          <div
            v-if="'svg' in media && media.svg"
            class="size-full flex items-center justify-center"
          >
            <div
              class="size-128 lg:size-144 bg-gray-100 text-blue-700 rounded-full p-30 lg:p-36 transition group-hover:bg-blue-500 group-hover:text-white group-hover:scale-110"
            >
              <MediaIcon v-bind="media" class="size-full" />
            </div>
          </div>
          <MediaImage v-else v-bind="media" :image-style="imageStyle" />
        </div>
      </slot>
    </div>
    <div v-if="tagline">{{ tagline }}</div>
    <h3
      v-if="title"
      class="mb-8 font-condensed text-xl font-bold !leading-tight md:mb-12 md:text-2xl lg:text-3xl text-balance"
      :class="{
        'text-blue-700 group-hover:text-blue-500': to,
      }"
    >
      {{ title }}
    </h3>
    <div
      v-if="bodyValue"
      class="ck-content mb-15 last:mb-0"
      v-html="bodyValue"
    />
    <div
      v-if="to"
      class="mt-auto flex size-40 items-center justify-center rounded-full bg-blue-700 text-white group-hover:bg-blue-500"
    >
      <SpriteSymbol class="size-3/5" name="arrow-right" />
    </div>
    <slot />
  </DrupalLink>
</template>

<script lang="ts" setup>
import type { MediaIconFragment, MediaImageFragment } from '#graphql-operations'
import type { NuxtSvgSpriteSymbol } from '#nuxt-svg-sprite/runtime'
import { stripTags } from '~/helpers'

const props = defineProps<{
  to?: string
  tagline?: string
  title?: string | undefined | null
  intro?: string
  body?: string
  alt?: string
  media?: MediaImageFragment | MediaIconFragment
  symbol?: NuxtSvgSpriteSymbol
  inlineIcon?: boolean
}>()

const imageStyle = defineImageStyle({
  type: 'sizes',
  aspectRatio: 4 / 3,
  sizes: {
    xs: 728,
    sm: 1000,
    md: 912,
    lg: 912,
  },
})

const icon = computed<MediaIconFragment | undefined>(() =>
  props.media && 'svg' in props.media ? props.media : undefined,
)

const bodyValue = computed(() => {
  if (!props.body) {
    return
  }
  if (props.to) {
    return stripTags(props.body)
  }

  return props.body
})

defineOptions({
  name: 'Teaser',
})
</script>
