<template>
  <div
    :id="id"
    class="paragraph-container mt-24 lg:mt-48 xl:mt-64"
    :class="{
      'bg-highlight': options.background === 'highlighted',
      'bg-gray-100': options.background === 'gray',
      'py-40 xl:py-64': options.background !== 'transparent',
      '!mt-0': options.background !== 'transparent' && index === 0,
    }"
  >
    <BlokkliField :list="paragraphs" name="field_paragraphs" />
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphContainerFragment } from '#graphql-operations'

defineProps<{ paragraphs?: ParagraphContainerFragment['paragraphs'] }>()

const { options, index } = defineBlokkli({
  bundle: 'container',
  globalOptions: ['anchorId'],
  options: {
    background: {
      type: 'radios',
      label: 'Hintergrund',
      displayAs: 'colors',
      default: 'transparent',
      options: {
        transparent: { label: 'Transparent', class: 'bg-white' },
        highlighted: { label: 'Hervorgehoben', class: 'bg-highlight' },
        gray: { label: 'Grau', class: 'bg-gray-400' },
      },
    },
  },
  editor: {
    addBehaviour: 'no-form',
  },
})

const id = useAnchorId(options)

useParagraphContext(options)

defineOptions({
  name: 'ParagraphContainer',
})
</script>

<style lang="postcss">
.paragraph-container + .paragraph-container {
  @apply !mt-0;
}
</style>
