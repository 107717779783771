import { notNullish } from '@vueuse/core'
import type { ObservedClientRect } from '~/plugins/clientRects'

export default function () {
  const rectsState = useState<Record<string, ObservedClientRect | null>>(
    'client_rects',
    () => {
      return {}
    },
  )

  const rects = computed(() => {
    return Object.values(rectsState.value).filter(notNullish)
  })

  return { rects }
}
