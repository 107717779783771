<template>
  <div>
    <slot />
    <div v-if="tagline" class="mb-16 text-xl font-bold">
      {{ tagline }}
    </div>
    <h3 class="typo-h2 font-condensed">
      {{ title }}
    </h3>
    <div v-if="allJobTitles.length" class="typo-lead-3 mt-8 font-condensed">
      <div v-for="jobTitle in allJobTitles" :key="jobTitle">{{ jobTitle }}</div>
    </div>
    <table class="person-card-table mt-12 text-left">
      <tbody>
        <tr v-if="yearOfBirth">
          <th>{{ $texts('yearOfBirth', 'Year of birth') }}</th>
          <td>
            {{ yearOfBirth }}
          </td>
        </tr>
        <template v-if="availability">
          <template v-if="typeof availability === 'string'">
            <tr>
              <th>
                {{ $texts('availabilityFrom', 'I am available from') }}
              </th>
              <td>
                {{ availability }}
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <th>{{ $texts('availability', 'I am available on') }}</th>
              <td>
                <span
                  v-for="(item, index) in availability"
                  :key="item.long"
                  :title="item.long"
                  >{{ item.short
                  }}<template v-if="index < availability.length - 1"
                    >,&nbsp;</template
                  ></span
                >
              </td>
            </tr>
          </template>
        </template>
        <tr v-if="phone?.first">
          <th>{{ $texts('phone', 'Phone') }}</th>
          <td>
            <FieldItemTypeTelephone
              v-bind="phone.first"
              :title="getLinkTitle($texts('callPerson', 'Call @person'))"
              class="inline-link"
            />
          </td>
        </tr>
        <tr v-if="email">
          <th>{{ $texts('email', 'Email') }}</th>
          <td>
            <a
              :href="getEmailLink(email)"
              :title="
                getLinkTitle(
                  $texts('sendEmailToPerson', 'Send email to @person'),
                )
              "
              class="inline-link"
              >{{ email }}</a
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts" setup>
import { nonNullable } from '#vuepal/helpers/type'
import type { WeekdayItem } from '~/types/global'
import type { PersonCardFragment } from '#graphql-operations'
import { removeWhitespace } from '~/helpers'

function abbreviateWeekday(day: string, language: string) {
  if (language === 'fr') {
    return day.substring(0, 3).toLowerCase()
  } else if (language === 'it') {
    return day.substring(0, 3).toLowerCase()
  }
  return day.substring(0, 2)
}

const language = useCurrentLanguage()
const { $texts } = useNuxtApp()

const props = defineProps<{
  id?: string
  familyName?: string
  givenName?: string
  jobTitles?: PersonCardFragment['jobTitles']
  weekdayAvailability?: PersonCardFragment['weekdayAvailability']
  email?: PersonCardFragment['email']
  phone?: PersonCardFragment['phone']
  yearOfBirth?: PersonCardFragment['yearOfBirth']
  image?: PersonCardFragment['image']
  tagline?: string
}>()

const title = computed(() =>
  [props.givenName, props.familyName].filter(Boolean).join(' '),
)

const allJobTitles = computed<string[]>(
  () => props.jobTitles?.map((v) => v.title).filter(nonNullable) || [],
)

const availability = computed<WeekdayItem[] | string | undefined>(() => {
  if (!props.weekdayAvailability) {
    return
  }

  const entireWeek =
    props.weekdayAvailability.includes('monday') &&
    props.weekdayAvailability.includes('friday') &&
    props.weekdayAvailability.length === 5

  if (entireWeek) {
    return [getWeekdayLabel('monday'), getWeekdayLabel('friday')].join(' - ')
  }

  if (props.weekdayAvailability.length) {
    return (props.weekdayAvailability || []).map((v) => {
      const long = getWeekdayLabel(v)
      return { long, short: abbreviateWeekday(long, language.value) }
    })
  }

  return undefined
})

function getEmailLink(email: string) {
  return 'mailto:' + removeWhitespace(email)
}

function getWeekdayLabel(key: string): string {
  switch (key) {
    case 'monday':
      return $texts('monday', 'Monday')
    case 'tuesday':
      return $texts('tuesday', 'Tuesday')
    case 'wednesday':
      return $texts('wednesday', 'Wednesday')
    case 'thursday':
      return $texts('thursday', 'Thursday')
    case 'friday':
      return $texts('friday', 'Friday')
    case 'saturday':
      return $texts('saturday', 'Saturday')
    case 'sunday':
      return $texts('sunday', 'Sunday')
  }

  return key
}
function getLinkTitle(translation = ''): string {
  return translation.replace('@person', title.value || '')
}
</script>

<style lang="postcss">
.person-card-table {
  td,
  th {
    @apply pb-8;
  }

  th {
    @apply pr-12;
  }
}
</style>
