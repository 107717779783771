<template>
  <div
    :id="id"
    :class="{
      container: !parentType || parentType === 'container',
      'my-16 md:my-20 lg:mt-64':
        parentType !== 'person_list' && parentType !== 'nav_section',
      'first:mt-0': parentType,
    }"
  >
    <Component
      :is="headingTag"
      v-blokkli-editable:field_title
      class="font-condensed font-bold"
      :class="[
        { 'text-white': background === 'highlighted' },
        'typo-h' + level,
      ]"
    >
      {{ title }}
    </Component>
    <p
      v-if="text"
      v-blokkli-editable:field_text
      class="mt-12 font-condensed"
      :class="{
        'text-white': background === 'highlighted',
        'typo-lead-2': level === 2,
        'typo-lead-3': level === 3,
      }"
      v-text="text"
    />
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  title?: string
  text?: string
}>()

const { parentType, options } = defineBlokkli({
  bundle: 'title',
  globalOptions: ['anchorId'],
  editor: {
    editTitle: (el) => el.querySelector('h2')?.textContent,
    addBehaviour: 'no-form',
  },
})

const id = useAnchorId(options, props)

const { background } = useParagraphContext()

const level = computed(() =>
  parentType.value === 'nav_section' || parentType.value === 'person_list'
    ? 3
    : 2,
)

const headingTag = computed(() => 'h' + level.value)

defineOptions({
  name: 'ParagraphTitle',
})
</script>
