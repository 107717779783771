<template>
  <div
    class="sticky top-0 z-40 overflow-auto border-b border-b-gray-200 bg-gray-50"
  >
    <nav class="is-wide container">
      <ul class="flex gap-24">
        <li v-for="(item, index) in items" :key="index">
          <a
            :href="item.url"
            class="flex h-50 items-center whitespace-nowrap border-b-3 border-b-transparent text-lg lg:h-64"
            :class="{
              '!border-b-blue-500': item.id === activeId,
            }"
            >{{ item.label }}</a
          >
        </li>
      </ul>
    </nav>
  </div>
</template>

<script lang="ts" setup>
import { notNullish } from '@vueuse/core'
import type { ParagraphNavSectionFragment } from '#build/graphql-operations'
import { toValidHtmlId } from '~/helpers'

const { rootBlocks } = defineBlokkliFragment({
  name: 'in_page_nav',
  label: 'In-Page Navigation',
  description:
    'Zeigt eine In-Page Navigation mit Links zu allen «Navigationsabschnitt» Paragraphen.',
})

const { rects } = useClientRects()

const activeId = computed(() => {
  return rects.value
    .filter((v) => v.isVisible)
    .sort((a, b) => a.rect.y - b.rect.y)[0]?.id
})

const items = computed(() =>
  rootBlocks.value
    .map((v) => {
      if (v.bundle !== 'nav_section') {
        return null
      }

      const navSectionProps: ParagraphNavSectionFragment | null =
        v.props as ParagraphNavSectionFragment | null

      if (!navSectionProps?.navSectionTitle) {
        return null
      }

      const label = navSectionProps.navSectionTitle
      const id = toValidHtmlId('nav-' + label)
      return {
        label,
        id,
        url: '#' + id,
      }
    })
    .filter(notNullish),
)
</script>
