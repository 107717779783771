type BackgroundColor = 'transparent' | 'highlighted' | 'gray'
type BackgroundAwareOptions = {
  background: BackgroundColor
}

export default function (options?: ComputedRef<BackgroundAwareOptions>) {
  const providedBackground = inject<ComputedRef<BackgroundColor> | null>(
    'paragraph_context',
    null,
  )

  const background = computed<BackgroundColor>(
    () =>
      options?.value.background || providedBackground?.value || 'transparent',
  )

  provide('paragraph_context', background)

  return { background }
}
