<template>
  <h2
    v-blokkli-editable:field_title
    class="typo-h2 -mt-8 text-balance font-condensed font-bold md:pr-40 lg:pr-64"
  >
    {{ title }}
  </h2>
</template>

<script lang="ts" setup>
defineProps<{
  title?: string
  text?: string
}>()

defineBlokkli({
  bundle: 'title',
  renderFor: [
    {
      fieldList: 'nav_title',
    },
  ],
  editor: {
    editTitle: (el) => el.querySelector('h2')?.textContent,
    addBehaviour: 'no-form',
  },
})

defineOptions({
  name: 'ParagraphTitleInsideNavSectionHeader',
})
</script>
