<template>
  <div class="grid items-center md:grid-cols-12">
    <div class="-mx-16 md:col-span-7 md:col-start-1 md:row-start-1 md:mx-0">
      <slot name="image">
        <div v-if="media" class="aspect-4/3 overflow-hidden md:rounded-huge">
          <div
            v-if="'svg' in media"
            class="size-full bg-gray-100 flex items-center justify-center"
          >
            <MediaIcon v-bind="media" class="size-[70%] text-gray-200" />
          </div>
          <MediaImage
            v-else
            v-bind="media"
            :image-style="imageStyle"
            img-class="w-full"
          />
        </div>
      </slot>
    </div>
    <div
      class="relative z-10 -mt-48 md:col-start-8 md:col-end-[-1] md:row-start-1 md:-ml-170 md:mt-0"
    >
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { MediaImageFragment, MediaIconFragment } from '#graphql-operations'

defineProps<{
  media?: MediaImageFragment | MediaIconFragment | undefined | null
}>()

const imageStyle = defineImageStyle({
  type: 'sizes',
  aspectRatio: 4 / 3,
  sizes: {
    xs: 728,
    sm: 1000,
    md: 912,
    lg: 912,
  },
})
</script>

<style lang="postcss">
.overlayed-teaser {
  .grid-area-b {
    @apply -mt-32 mobile-only:px-16;
  }
}
.overlayed-teaser-image {
  /* margin: 0 calc(var(--section-gap-h) * -1); */
}
</style>
