<template>
  <thead>
    <tr>
      <th>{{ ageRow }}</th>
      <th class="!text-right">
        {{ firstRow }}
      </th>
      <th v-if="secondRow" class="!text-right">
        {{ secondRow }}
      </th>
      <th v-if="type === 'default'" class="!text-right">
        {{
          $texts(
            'pension_fund.totalRetirementCredits',
            'Total retirement credits',
          )
        }}
      </th>
    </tr>
  </thead>
</template>

<script setup lang="ts">
import type { FieldListItemTyped } from '#blokkli/generated-types'
import type { ParagraphSavingsContributionEntryFragment } from '#build/graphql-operations'
import { notNullish } from '@vueuse/core'

const props = defineProps<{
  type: 'default' | 'voluntary'
  overrides?: string[]
  items: FieldListItemTyped[]
}>()

const { $texts } = useEasyTexts()

const ageRow = computed(() => {
  return props.overrides?.[0] || $texts('pension_fund.ageScale', 'Age scale')
})

const firstRow = computed(() => {
  if (props.overrides?.[1]) {
    return props.overrides[1]
  }
  if (props.type === 'default') {
    return $texts(
      'pension_fund.contributionEmployee',
      'Savings contribution of the employee',
    )
  }

  return $texts('pension_fund.contributionVoluntary1', 'Variante 1')
})

const childrenHaveSecondValue = computed(() => {
  return props.items.every((v) => {
    if (v.bundle !== 'savings_contribution_entry') {
      return true
    }

    const childProps = v.props as ParagraphSavingsContributionEntryFragment

    return notNullish(childProps.rateTwo)
  })
})

const secondRow = computed(() => {
  if (props.type === 'default') {
    return (
      props.overrides?.[2] ||
      $texts(
        'pension_fund.contributionEmployer',
        "Employer's savings contribution",
      )
    )
  }

  if (childrenHaveSecondValue.value) {
    return (
      props.overrides?.[2] ||
      $texts('pension_fund.contributionVoluntary2', 'Variante 2')
    )
  }

  return null
})
</script>
