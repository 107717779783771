<template>
  <Container class="my-20">
    <figure
      :id="id"
      :class="{
        'text-white': background === 'highlighted',
      }"
    >
      <blockquote
        class="text-balance font-condensed text-4xl before:content-['«'] after:content-['»'] lg:text-5xl"
      >
        {{ quote }}
      </blockquote>
      <figcaption v-if="name" class="mt-12">
        <p v-blokkli-editable:field_title class="font-bold">
          {{ name }}
        </p>
        <p v-if="description" v-blokkli-editable:field_subtitle>
          {{ description }}
        </p>
      </figcaption>
    </figure>
  </Container>
</template>

<script lang="ts" setup>
defineProps<{
  quote?: string
  name?: string
  description?: string
}>()

const { options } = defineBlokkli({
  globalOptions: ['anchorId'],
  bundle: 'quote',
})

const id = useAnchorId(options)

defineOptions({
  name: 'ParagraphQuote',
})

const { background } = useParagraphContext()
</script>
