<template>
  <tr>
    <td class="whitespace-nowrap" v-html="ageScale" />
    <td class="!text-right">{{ formatPercentage(rateOne) }}</td>
    <td v-if="interestRateType === 'default' || rateTwo" class="!text-right">
      {{ formatPercentage(rateTwo) }}
    </td>
    <td v-if="interestRateType === 'default'" class="!text-right">
      {{ formatPercentage(total) }}
    </td>
  </tr>
</template>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    ageGroupStart?: number
    rateOne?: number
    rateTwo?: number
  }>(),
  {
    ageGroupStart: 0,
    rateOne: 0,
    rateTwo: 0,
  },
)

const interestRateType =
  inject<ComputedRef<'default' | 'voluntary'>>('interest_rate_type')

const { formatPercentage } = useLocale()

const { index, siblings } = defineBlokkli({
  bundle: 'savings_contribution_entry',
})

const nextAgeGroup = computed(() => {
  const item = siblings.value[index.value + 1]
  if (
    item &&
    item.bundle === 'savings_contribution_entry' &&
    item.props &&
    item.props.ageGroupStart &&
    typeof item.props.ageGroupStart === 'number'
  ) {
    return item.props.ageGroupStart - 1
  }

  return 70
})

defineOptions({
  name: 'ParagraphSavingsContributionEntry',
})

const ageScale = computed(() => {
  return `${props.ageGroupStart} - ${nextAgeGroup.value}`
})

const total = computed(() => props.rateOne + props.rateTwo)
</script>
