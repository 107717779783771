<template>
  <LinkListItem
    v-if="parentType === 'link_list' && (isEditing || to)"
    :to="to"
    :title="title"
  />
  <div v-else-if="needsContainer && to" class="container">
    <DrupalLink :to="to" class="link mr-12">
      <span>{{ title }}</span>
      <SpriteSymbol name="arrow-right" />
    </DrupalLink>
  </div>
  <DrupalLink v-else-if="to || isEditing" :to="to" class="link mr-12">
    <span>{{ title }}</span>
    <SpriteSymbol name="arrow-right" />
  </DrupalLink>
</template>

<script lang="ts" setup>
import type { ParagraphLinkFragment } from '#graphql-operations'

const props = defineProps<{
  link?: ParagraphLinkFragment['link']
}>()

const { parentType, isEditing } = defineBlokkli({
  bundle: 'link',
  editor: {
    editTitle: (el) => el.querySelector('a')?.textContent,
  },
})

const { $texts } = useEasyTexts()

const to = computed(() => props.link?.uri?.path || '')
const title = computed(() => {
  if (props.link?.linkTitle) {
    return props.link.linkTitle
  }
  const entity =
    props.link?.uri && 'entity' in props.link.uri ? props.link.uri.entity : null

  if (entity?.label) {
    return entity.label
  }

  return $texts('teaserCta', 'Mehr erfahren')
})

const needsContainer = computed(
  () => !parentType.value || parentType.value === 'container',
)

defineOptions({
  name: 'ParagraphLink',
})
</script>
