<template>
  <div class="my-32 first:mt-0 last:mb-0">
    <BlokkliField :list="paragraphsHeader" name="field_paragraphs_header" />
    <div class="table-container">
      <table>
        <thead>
          <tr>
            <th class="w-1/4 whitespace-nowrap">
              {{ $texts('name', 'Name') }}
            </th>
            <th class="whitespace-nowrap">
              {{ $texts('employer', 'Employer') }}
            </th>
            <th class="w-1/4 whitespace-nowrap">
              {{ $texts('pension_fund.memberFunction', 'Function') }}
            </th>
            <th class="whitespace-nowrap">
              {{ $texts('contact', 'Contact') }}
            </th>
          </tr>
        </thead>
        <BlokkliField tag="tbody" :list="paragraphs" name="field_paragraphs" />
      </table>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphPersonListFragment } from '#graphql-operations'

defineProps<{
  paragraphs?: ParagraphPersonListFragment['paragraphs']
  paragraphsHeader?: ParagraphPersonListFragment['paragraphsHeader']
}>()

defineBlokkli({
  bundle: 'person_list',
  editor: {
    addBehaviour: 'no-form',
  },
})

defineOptions({
  name: 'ParagraphPersonList',
})
</script>
