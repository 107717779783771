function getLocale(langcode: string) {
  if (langcode === 'en') {
    return 'en-GB'
  }

  return langcode + '-CH'
}

function parseFloatForPercentage(v: number | string): number {
  if (typeof v === 'number') {
    return v
  }

  const properNumberString = v.trim().replace(',', '.')
  return parseFloat(properNumberString)
}

function formatPercentage(
  v: number | undefined | null | string,
  language: string,
): string {
  if (v === undefined || v === null) {
    return '-'
  }

  const num = parseFloatForPercentage(v)
  const formatted = num.toFixed(2) + '%'

  if (language === 'en') {
    return formatted
  }

  return formatted.replace('.', ',')
}

function formatDate(
  date: Date,
  locale: string,
  options?: Intl.DateTimeFormatOptions,
) {
  return date.toLocaleDateString(
    locale,
    options || {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    },
  )
}

export default function () {
  const language = useCurrentLanguage()

  return {
    formatPercentage: function (v?: number | string): string {
      return formatPercentage(v, language.value)
    },
    formatDate: function (
      date: Date,
      options?: Intl.DateTimeFormatOptions,
    ): string {
      return formatDate(date, getLocale(language.value), options)
    },
  }
}
