<template>
  <div
    :class="{
      'container my-16 md:my-24 lg:my-48 last:mb-0': parentType !== 'grid',
    }"
  >
    <figure v-if="videoUrl" class="overflow-hidden rounded-large group">
      <iframe
        v-if="iframeUrl && isPlaying"
        :src="iframeUrl"
        :title="videoTitle"
        allow="autoplay; encrypted-media; picture-in-picture"
        allowfullscreen
        class="aspect-video w-full md:px-40 lg:px-0"
      />

      <button
        v-if="!isPlaying"
        class="relative flex aspect-video w-full cursor-pointer items-center justify-center md:px-40 lg:px-0"
        :aria-label="$texts('video.load', 'Video laden')"
        @click.prevent="isPlaying = true"
      >
        <RokkaImage
          v-if="video?.customThumbnail?.file?.rokka?.hash"
          :hash="video.customThumbnail.file.rokka.hash"
          :config="imageStyle"
          img-class="absolute left-0 top-0 size-full object-cover"
        />
        <img
          v-else-if="video?.oembed?.thumbnailUrl?.path"
          :src="video.oembed.thumbnailUrl.path"
          class="absolute left-0 top-0 size-full object-cover"
          :alt="videoTitle"
        />

        <SpriteSymbol
          name="play"
          class="z-10 size-[75px] transition ease-in-out group-hover:scale-110"
        />
      </button>
    </figure>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphRemoteVideoFragment } from '#graphql-operations'

const props = defineProps<{
  video?: ParagraphRemoteVideoFragment['video']
}>()

const { parentType } = defineBlokkli({
  bundle: 'remote_video',
  editor: {
    editTitle: (el) => el.querySelector('h2')?.innerText,
  },
})

const isPlaying = ref(false)

const videoUrl = computed(() => props.video?.url)
const videoTitle = computed(() => props.video?.title)

const iframeUrl = computed(() => {
  const url = props.video?.oembed?.iframeUrl?.path
  if (!url) {
    return
  }

  if (url.includes('?')) {
    return url + '&autoplay=1'
  }
  return url + '?autoplay=1'
})

const imageStyle = defineImageStyle({
  type: 'sizes',
  aspectRatio: 16 / 9,
  sizes: {
    xs: 770,
    sm: 984,
    md: 1380,
    lg: 1380,
  },
})

defineOptions({
  name: 'ParagraphRemoteVideo',
})
</script>
